import axios from 'axios'
import qs from 'qs'

const get = async (url, params) => {
  const res = await axios.get(url, {
    params: params
  })
  let data = res.data
  return data
}

const post = async (url, params) => {
  const res = await axios.post(url, qs.stringify(params))
  let data = res.data
  return data
}

const postJson = async (url, params) => {
  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const res = await axios.post(url, params, config)
  let data = res.data
  return data
}

const postImg = async (url, params) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const res = await axios.post(url, params, config)
  let data = res.data
  return data
}

export default {
  get,
  post,
  postJson,
  postImg
}
