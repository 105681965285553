import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.ignoredElements = [ 
	'wx-open-launch-app',
	'wx-open-launch-weapp'
]

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
