import request from './request.js'

const BASE_URL = 'https://pintuan.syisa.com/jeecg-boot/'

// 微信jssdk
const JsSdkSign = async (params) => {
  return await request.post(BASE_URL + 'api/wxuser/JsSdkSign', params)
}

// 获取scheme
const getScheme = async (params) => {
  return await request.postJson(BASE_URL + 'wxapp/scheme', params)
}

export default {
  JsSdkSign,
  getScheme
}
