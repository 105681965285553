<template>
  <div class="login">
		<img class="bg" src="../assets/login2.png" />
		<div v-if="!isWeChat" @click="handleDownload" class="delivery-btn" :data-clipboard-text="extinfo">
			<img class="weixin" src="../assets/logo.png" />
		</div>
     
		<div v-else>
			<wx-open-launch-app
			  appid="wx96166db417a388be"
			  :extinfo="extinfo"
				@launch="openLaunch"
				@error="openError"
			>
			  <script type="text/wxtag-template">
			    <style>
			      .btn { 
			        width:260px;
							height:50px;
							line-height: 50px;
							font-size: 18px;
							border:none;
							color:#fff;
							border-radius: 50px;
							background-image: linear-gradient(to right, #e8221c , #e86b67);
			      }
			    </style>
			    <button class="btn">打开来趣拼</button>
			  </script>
			</wx-open-launch-app>
		</div>
    <!-- 底部归属 -->
    <div class="bottom">
  		<div class="">Copyright©2021</div>
  		<div class="">{{ appName }} 版权所有</div>
    </div>
  </div>
</template>

<script>
	import AJAX from '@/utils/AJAX.js'
	import Clipboard from 'clipboard'
	export default {
		name: 'Home',
		data() {
			return {
				appName: '泉州亿成信息科技有限公司',
				isWeChat: true,
				isIos: false,
				inviteCode: '',
				actConfigId: '',
				departId: '',
				extinfo: '',
			}
		},
		created() {
			let that = this
			this.isWeChatHandler()
			this.extinfo = ''
			this.inviteCode = this.getQueryVariable('inviteCode')
			this.actConfigId = this.getQueryVariable('actConfigId')
			this.departId = this.getQueryVariable('departId')
			if(this.inviteCode) {
				this.extinfo = this.extinfo + 'inviteCode=' + this.inviteCode
			}
			if(this.departId) {
				this.extinfo = this.extinfo + 'departId=' + this.departId
			}
			if(this.actConfigId) {
				this.extinfo = this.extinfo + '&actConfigId=' + this.actConfigId
			}
			ShareTrace.init({
				appkey: "c12bb2a4978339ec", // 此值必填
				param: that.extinfo,
				success: function() {
					console.log("init success...")
				},
				error: function(msg) {
					console.log(msg)
				}
			})
		},
		mounted() {
		},
		methods: {
			getQueryVariable(name){
				var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
				var r = window.location.search.substr(1).match(reg);
				if (r != null) {
					return unescape(r[2]);
				}
				return null;
			},
			isWeChatHandler() {
			  const ua = window.navigator.userAgent.toLowerCase()
			  if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger' && ua.match(/Android/i)) {
			    console.log('微信登录')
			    this.isWeChat  = true
			    this.JsSdkSign();
			  } else {
			    console.log('非微信登录')
					this.isWeChat = false
			  }
				if(!ua.match(/Android/i)){
					this.isIos = true
				}
			},
			// 微信sdk
			async JsSdkSign() {
				let that = this;
				let url = window.location.href
				let params = {
					url: url
				};
				console.log('params', params)
				let res = await AJAX.JsSdkSign(params);
				let data = res.result
				console.log('JsSdkSign', res)
				window.wx.config({
					debug: false,
					appId: data.appId,
					timestamp: data.timestamp,
					nonceStr: data.nonceStr,
					signature: data.signature,
					jsApiList: [],
					openTagList: ['wx-open-launch-app'],
					success: function() {
						console.log("配置jssdk， 成功！");
						that.checkApi();
					},
					fail: function() {
						console.log("配置jssdk， 失败！");
					}
				});
			},
			checkApi: function(res) {
				window.wx.checkJsApi({
					openTagList: ['wx-open-launch-app'],
					success: function(res) {
						console.log('检查微信接口', res)
						// 以键值对的形式返回，可用的api值true，不可用为false
						// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
					}
				});
			},
			handleDownload() {
				this.copy()
				// 请在用户点击下载按钮时，触发此函数
				ShareTrace.download();
			},
			// 把信息复制到粘贴板
			copy(){
				if (this.isIos){
					let clipboard = new Clipboard('.delivery-btn');
					clipboard.on('success', function(e) {
						console.log("复制成功");
					  e.clearSelection()
					});
					clipboard.on('error', function(e) {
						console.log("复制失败");
					})
				}
			},
			openLaunch(e) {
			  console.log('打开成功', e)
			},
			
			openError(e) {
				ShareTrace.download();
			  console.log('打开失败', e)
			},
		}
	}
</script>

<style>
	.login{
		background-color: #FFFFFF;
		height: 100%;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		margin-top: -10px;
	}
	.bg{
		width: 30rem;
		height: 30rem;
	}
	.weixin{
		width: 17.0625rem;
		height: 4.25rem;
	}
	.bottom{
		margin-top: 9.375rem;
		text-align: center;
		font-size: 0.8125rem;
		color: #666666;
	}
</style>
